<template>
  <div class='error'>
    <layout-header />
    <main class='error__main'>
      <section class='error__main__modal'>
        <h3>{{ setInfo('title') }}</h3>
        <p>{{ setInfo('text') }}</p>
        <button-normal>
          <router-link :to='{ name: "Dashboard" }'>
            トップページに戻る
          </router-link>
        </button-normal>
      </section>
    </main>
    <layout-footer />
  </div>
</template>

<script>
import LayoutHeader from '@/components/layouts/LayoutHeader.vue';
import LayoutFooter from '@/components/layouts/LayoutFooter.vue';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    ButtonNormal,
  },
  created() {
    if (this.judgeSphone()) {
      this.$router.push({ name: `Sp${this.$route.name}` });
    }
  },
  data() {
    return {
      pageInfoList: [
        {
          title: 'Error',
          text: 'ページの読み込み中にエラーが発生しました',
        },
        {
          title: 'NotFound',
          text: '該当のページは見つかりませんでした',
        },
      ],
    };
  },
  methods: {
    setInfo(target) {
      // smpのページ遷移前のエラー回避
      if (this.judgeSphone()) {
        return '';
      }
      return this.pageInfoList.filter((pageInfo) => pageInfo.title
        === this.$route.name)[0][target];
    },
    judgeSphone() {
      return (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0) && navigator.userAgent.indexOf('Mobile') > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.error__main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.error__main__modal {
  background-color: $color-white;
  width: 500px;
  text-align: center;
  padding: 30px 30px 35px;
  h3 {
    font-size: 24px;
  }
  p {
    font-size: 14px;
    margin: 10px 0 20px;
  }
  a {
    text-decoration: none;
    color: $color-black;
  }
  a:visited {
    color: $color-black;
  }
}
</style>
